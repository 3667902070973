import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, vModelCheckbox as _vModelCheckbox, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = { class: "d-flex" }
const _hoisted_3 = { class: "form-group" }
const _hoisted_4 = { class: "d-flex mb-2" }
const _hoisted_5 = ["value"]
const _hoisted_6 = { class: "ms-4" }
const _hoisted_7 = { class: "form-group" }
const _hoisted_8 = { class: "mb-2" }
const _hoisted_9 = { class: "form-group" }
const _hoisted_10 = { class: "w-100 d-flex align-items-center justify-content-between py-2" }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { class: "bg-body-secondary py-2 px-4" }
const _hoisted_13 = { class: "mb-2" }
const _hoisted_14 = { class: "form-group" }
const _hoisted_15 = { class: "w-100 d-flex align-items-center justify-content-between py-2" }
const _hoisted_16 = ["onClick"]
const _hoisted_17 = { class: "bg-body-secondary py-2 px-4" }
const _hoisted_18 = { class: "table table-striped" }
const _hoisted_19 = { class: "text-end" }
const _hoisted_20 = ["onClick"]
const _hoisted_21 = { class: "form-group" }
const _hoisted_22 = { class: "bg-body-secondary py-2 px-4" }
const _hoisted_23 = { class: "table table-striped" }
const _hoisted_24 = { class: "pre" }
const _hoisted_25 = { class: "pre" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_toggler = _resolveComponent("toggler")!
  const _component_file_import_config = _resolveComponent("file-import-config")!
  const _component_accordion = _resolveComponent("accordion")!
  const _component_trigger_config = _resolveComponent("trigger-config")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[9] || (_cache[9] = _createElementVNode("label", null, "Nome config", -1)),
      _withDirectives(_createElementVNode("input", {
        type: "text",
        class: "form-control",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.config.name) = $event))
      }, null, 512), [
        [_vModelText, _ctx.config.name]
      ])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[11] || (_cache[11] = _createElementVNode("label", null, "Moduli onOffice da scaricare", -1)),
        _createElementVNode("div", _hoisted_4, [
          _withDirectives(_createElementVNode("input", {
            type: "checkbox",
            class: "form-control",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.allOnOfficeModules) = $event))
          }, null, 512), [
            [_vModelCheckbox, _ctx.allOnOfficeModules]
          ]),
          _cache[10] || (_cache[10] = _createTextVNode(" Tutti "))
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modules, (item, i) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "d-flex mb-2",
            key: i
          }, [
            _withDirectives(_createElementVNode("input", {
              type: "checkbox",
              class: "form-control",
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.config.onOfficeModules) = $event)),
              value: item
            }, null, 8, _hoisted_5), [
              [_vModelCheckbox, _ctx.config.onOfficeModules]
            ]),
            _createTextVNode(" " + _toDisplayString(item), 1)
          ]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _cache[12] || (_cache[12] = _createElementVNode("label", null, "Modalità test per API onOffice (scarica solo le prime 10 righe per ogni tabella)", -1)),
          _withDirectives(_createElementVNode("input", {
            type: "checkbox",
            class: "form-control",
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.config.isTestMode) = $event))
          }, null, 512), [
            [_vModelCheckbox, _ctx.config.isTestMode]
          ])
        ])
      ])
    ]),
    _cache[22] || (_cache[22] = _createElementVNode("hr", null, null, -1)),
    _createElementVNode("div", _hoisted_8, [
      _cache[13] || (_cache[13] = _createElementVNode("span", null, "Configurazione file", -1)),
      _cache[14] || (_cache[14] = _createTextVNode()),
      _createElementVNode("button", {
        class: "btn btn-sm btn-success ms-2 squaredPlus",
        onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.addFileConfig && _ctx.addFileConfig(...args)))
      }, "+")
    ]),
    _createElementVNode("div", _hoisted_9, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.config.fileImportConfigs, (item, i) => {
        return (_openBlock(), _createBlock(_component_accordion, {
          class: "mb-3",
          key: i
        }, {
          title: _withCtx(() => [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", null, [
                _createElementVNode("button", {
                  class: "btn btn-sm btn-danger me-2",
                  onClick: _withModifiers(($event: any) => (_ctx.removeFileConfig(i)), ["stop"])
                }, " Elimina ", 8, _hoisted_11),
                _createElementVNode("span", null, "File " + _toDisplayString(i + 1) + " - " + _toDisplayString(item.filePath), 1)
              ]),
              _createElementVNode("div", {
                class: "pe-2 d-flex align-items-center",
                onClick: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["prevent","stop"]))
              }, [
                _cache[15] || (_cache[15] = _createElementVNode("span", { class: "me-1" }, "Attivo", -1)),
                _createVNode(_component_toggler, {
                  modelValue: item.isActive,
                  "onUpdate:modelValue": ($event: any) => ((item.isActive) = $event)
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ])
            ])
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_12, [
              _createVNode(_component_file_import_config, {
                modelValue: _ctx.config.fileImportConfigs[i],
                "onUpdate:modelValue": ($event: any) => ((_ctx.config.fileImportConfigs[i]) = $event)
              }, null, 8, ["modelValue", "onUpdate:modelValue"])
            ])
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _cache[23] || (_cache[23] = _createElementVNode("hr", null, null, -1)),
    _createElementVNode("div", _hoisted_13, [
      _cache[16] || (_cache[16] = _createElementVNode("span", null, "Configurazione triggers", -1)),
      _cache[17] || (_cache[17] = _createTextVNode()),
      _createElementVNode("button", {
        class: "btn btn-sm btn-success ms-2 squaredPlus",
        onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.addTrigger && _ctx.addTrigger(...args)))
      }, "+")
    ]),
    _createElementVNode("div", _hoisted_14, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.config.triggerConfigs, (item, i) => {
        return (_openBlock(), _createBlock(_component_accordion, {
          class: "mb-3",
          key: i
        }, {
          title: _withCtx(() => [
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("div", null, [
                _createElementVNode("button", {
                  class: "btn btn-sm btn-danger me-2",
                  onClick: _withModifiers(($event: any) => (_ctx.removeTrigger(i)), ["stop"])
                }, " Elimina ", 8, _hoisted_16),
                _createElementVNode("span", null, "Trigger " + _toDisplayString(i + 1), 1)
              ]),
              _createElementVNode("div", {
                class: "pe-2 d-flex align-items-center",
                onClick: _cache[7] || (_cache[7] = _withModifiers(() => {}, ["prevent","stop"]))
              }, [
                _cache[18] || (_cache[18] = _createElementVNode("span", { class: "me-1" }, "Attivo", -1)),
                _createVNode(_component_toggler, {
                  modelValue: item.isActive,
                  "onUpdate:modelValue": ($event: any) => ((item.isActive) = $event)
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ])
            ])
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_17, [
              _createVNode(_component_trigger_config, {
                modelValue: _ctx.config.triggerConfigs[i],
                "onUpdate:modelValue": ($event: any) => ((_ctx.config.triggerConfigs[i]) = $event)
              }, null, 8, ["modelValue", "onUpdate:modelValue"])
            ])
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _cache[24] || (_cache[24] = _createElementVNode("hr", null, null, -1)),
    _createElementVNode("div", null, [
      _createElementVNode("button", {
        class: "btn btn-primary",
        onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.refreshJobInfos && _ctx.refreshJobInfos(...args)))
      }, "Refresh")
    ]),
    _createElementVNode("table", _hoisted_18, [
      _cache[19] || (_cache[19] = _createElementVNode("thead", null, [
        _createElementVNode("th", null, "Job name"),
        _createElementVNode("th", null, "Trigger name"),
        _createElementVNode("th", null, "Start time"),
        _createElementVNode("th", null, "Next fire time"),
        _createElementVNode("th", null, "Prev fire time"),
        _createElementVNode("th", null, "State"),
        _createElementVNode("th", { width: "100" })
      ], -1)),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.jobInfos, (item, i) => {
          return (_openBlock(), _createElementBlock("tr", { key: i }, [
            _createElementVNode("td", null, _toDisplayString(item.jobName), 1),
            _createElementVNode("td", null, _toDisplayString(item.triggerName), 1),
            _createElementVNode("td", null, _toDisplayString(_ctx.$filters.date(item.startTime, '', true)), 1),
            _createElementVNode("td", null, _toDisplayString(_ctx.$filters.date(item.nextFireTime, '', true)), 1),
            _createElementVNode("td", null, _toDisplayString(_ctx.$filters.date(item.previousFireTime, '', true)), 1),
            _createElementVNode("td", null, _toDisplayString(item.state), 1),
            _createElementVNode("td", _hoisted_19, [
              (item.state != 'Blocked')
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    class: "btn btn-sm btn-primary",
                    onClick: ($event: any) => (_ctx.runJob(item))
                  }, " Run now ", 8, _hoisted_20))
                : _createCommentVNode("", true)
            ])
          ]))
        }), 128))
      ])
    ]),
    _cache[25] || (_cache[25] = _createElementVNode("hr", null, null, -1)),
    _createElementVNode("div", _hoisted_21, [
      _createVNode(_component_accordion, { class: "mb-3" }, {
        title: _withCtx(() => _cache[20] || (_cache[20] = [
          _createElementVNode("div", { class: "w-100 d-flex align-items-center justify-content-between py-2" }, [
            _createElementVNode("div", null, " Log job ")
          ], -1)
        ])),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_22, [
            _createElementVNode("table", _hoisted_23, [
              _cache[21] || (_cache[21] = _createElementVNode("thead", null, [
                _createElementVNode("th", null, "Data ora"),
                _createElementVNode("th", null, "OnOffice"),
                _createElementVNode("th", null, "File")
              ], -1)),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.jobLogs, (item, i) => {
                  return (_openBlock(), _createElementBlock("tr", { key: i }, [
                    _createElementVNode("td", null, _toDisplayString(_ctx.$filters.date(item.date, 'yyyy-MM-DD HH:mm:ss:SSS')), 1),
                    _createElementVNode("td", _hoisted_24, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.onOfficeImportResult, (line, i) => {
                        return (_openBlock(), _createElementBlock("div", { key: i }, _toDisplayString(line), 1))
                      }), 128))
                    ]),
                    _createElementVNode("td", _hoisted_25, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.fileListImportResult, (line, i) => {
                        return (_openBlock(), _createElementBlock("div", { key: i }, _toDisplayString(line), 1))
                      }), 128))
                    ])
                  ]))
                }), 128))
              ])
            ])
          ])
        ]),
        _: 1
      })
    ])
  ]))
}