<template>
    <div class="form-group">
        <label><b>Percorso file</b> - premi <button class="d-inline btn btn-success p-2 btn-round btn-hollow"></button> per selezionare</label>
        <input type="text" readonly="true" class="form-control" v-model="localValue.filePath" />
        <file-tree-path-selector v-model="localValue.filePath" :nodes="nodes" @update:modelValue="setFileType"></file-tree-path-selector>
    </div>
    <div class="form-group">
        <label><b>File zip</b></label>
        <div>
            <toggler v-model="localValue.isZip"></toggler>
        </div>
    </div>
    <div class="form-group">
        <label><b>Tipo importazione</b></label>
        <select class="form-control" v-model="localValue.type">
            <option value="CsvFileImportConfig">CSV</option>
            <option value="SqlFileImportConfig">SQL</option>
            <option value="XlsxFileImportConfig">XLSX</option>
            <option value="XlsFileImportConfig">XLS</option>
        </select>
    </div>

    <excel-like-file-config v-model="localValue" v-if="localValue.type == 'CsvFileImportConfig' || localValue.type == 'XlsxFileImportConfig' || localValue.type == 'XlsFileImportConfig'"></excel-like-file-config>
    <sql-file-import-config v-model="localValue" v-if="localValue.type == 'SqlFileImportConfig'"></sql-file-import-config>

</template>

<script lang="ts">
import { mixins, Options, Vue } from 'vue-class-component';
import * as OM from '@/model';
import * as CONST from '@/const';
import WithModel from '@/mixins/withModel';
import { AppConfigClient } from '@/services/Services';

@Options({})
export default class fileImportConfig extends mixins(WithModel) {
    
    declare localValue: OM.BaseFileImportConfig;
    
    dataTypes = CONST.DataTypes.GetAll();
    
    nodes = {};
    
    created(){
        AppConfigClient.readFileSystemRoot()
        .then(x => {
            this.nodes = x;
        })
    }

    setFileType(){
        if(this.localValue.filePath.toLowerCase().indexOf(".sql") > -1){
            this.localValue.type = "SqlFileImportConfig";
        } else if(this.localValue.filePath.toLowerCase().indexOf(".csv") > -1){
            this.localValue.type = "CsvFileImportConfig";
        } else if(this.localValue.filePath.toLowerCase().indexOf(".xlsx") > -1){
            this.localValue.type = "XlsxFileImportConfig";
        } else if(this.localValue.filePath.toLowerCase().indexOf(".xls") > -1){
            this.localValue.type = "XlsFileImportConfig";
        }
    }
    
    
}
</script>
