import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelSelect as _vModelSelect, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = { class: "form-group" }
const _hoisted_3 = { class: "form-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_file_tree_path_selector = _resolveComponent("file-tree-path-selector")!
  const _component_toggler = _resolveComponent("toggler")!
  const _component_excel_like_file_config = _resolveComponent("excel-like-file-config")!
  const _component_sql_file_import_config = _resolveComponent("sql-file-import-config")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[6] || (_cache[6] = _createElementVNode("label", null, [
        _createElementVNode("b", null, "Percorso file"),
        _createTextVNode(" - premi "),
        _createElementVNode("button", { class: "d-inline btn btn-success p-2 btn-round btn-hollow" }),
        _createTextVNode(" per selezionare")
      ], -1)),
      _withDirectives(_createElementVNode("input", {
        type: "text",
        readonly: "true",
        class: "form-control",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localValue.filePath) = $event))
      }, null, 512), [
        [_vModelText, _ctx.localValue.filePath]
      ]),
      _createVNode(_component_file_tree_path_selector, {
        modelValue: _ctx.localValue.filePath,
        "onUpdate:modelValue": [
          _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localValue.filePath) = $event)),
          _ctx.setFileType
        ],
        nodes: _ctx.nodes
      }, null, 8, ["modelValue", "nodes", "onUpdate:modelValue"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _cache[7] || (_cache[7] = _createElementVNode("label", null, [
        _createElementVNode("b", null, "File zip")
      ], -1)),
      _createElementVNode("div", null, [
        _createVNode(_component_toggler, {
          modelValue: _ctx.localValue.isZip,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localValue.isZip) = $event))
        }, null, 8, ["modelValue"])
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _cache[9] || (_cache[9] = _createElementVNode("label", null, [
        _createElementVNode("b", null, "Tipo importazione")
      ], -1)),
      _withDirectives(_createElementVNode("select", {
        class: "form-control",
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.localValue.type) = $event))
      }, _cache[8] || (_cache[8] = [
        _createElementVNode("option", { value: "CsvFileImportConfig" }, "CSV", -1),
        _createElementVNode("option", { value: "SqlFileImportConfig" }, "SQL", -1),
        _createElementVNode("option", { value: "XlsxFileImportConfig" }, "XLSX", -1),
        _createElementVNode("option", { value: "XlsFileImportConfig" }, "XLS", -1)
      ]), 512), [
        [_vModelSelect, _ctx.localValue.type]
      ])
    ]),
    (_ctx.localValue.type == 'CsvFileImportConfig' || _ctx.localValue.type == 'XlsxFileImportConfig' || _ctx.localValue.type == 'XlsFileImportConfig')
      ? (_openBlock(), _createBlock(_component_excel_like_file_config, {
          key: 0,
          modelValue: _ctx.localValue,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.localValue) = $event))
        }, null, 8, ["modelValue"]))
      : _createCommentVNode("", true),
    (_ctx.localValue.type == 'SqlFileImportConfig')
      ? (_openBlock(), _createBlock(_component_sql_file_import_config, {
          key: 1,
          modelValue: _ctx.localValue,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.localValue) = $event))
        }, null, 8, ["modelValue"]))
      : _createCommentVNode("", true)
  ], 64))
}