<template>
    <div>
        <div class="form-group">
            <label>Nome config</label>
            <input type="text" class="form-control" v-model="config.name" />
        </div>
        <div class="d-flex">
            <div class="form-group">
                <label>Moduli onOffice da scaricare</label>
                <div class="d-flex mb-2">
                    <input type="checkbox" class="form-control" v-model="allOnOfficeModules" /> Tutti
                </div>
                <div class="d-flex mb-2" v-for="(item, i) in modules" :key="i">
                    <input type="checkbox" class="form-control" v-model="config.onOfficeModules" :value="item" />
                    {{item}}
                </div>
            </div>
            <div class="ms-4">
                <div class="form-group">
                    <label>Modalità test per API onOffice (scarica solo le prime 10 righe per ogni tabella)</label>
                    <input type="checkbox" class="form-control" v-model="config.isTestMode" />
                </div>
                <!-- <div class="form-group pt-2">
                    <button class="btn btn-primary btn-sm" @click="loadCalendar">Carica calendario onOffice dal 2023</button>
                </div>
                <div class="form-group pt-2">
                    <button class="btn btn-primary btn-sm" @click="loadTasks">Carica task onOffice dal 2023</button>
                </div> -->
            </div>
        </div>
        <hr/>
        <div class="mb-2">
            <span>Configurazione file</span> <button class="btn btn-sm btn-success ms-2 squaredPlus" @click="addFileConfig">+</button>
        </div>
        <div class="form-group">
            <accordion class="mb-3" v-for="(item, i) in config.fileImportConfigs" :key="i">
                <template #title>
                    <div class="w-100 d-flex align-items-center justify-content-between py-2">
                        <div>
                            <button class="btn btn-sm btn-danger me-2" @click.stop="removeFileConfig(i)">
                                Elimina
                            </button>
                            <span>File {{i + 1}} - {{item.filePath}}</span>
                        </div>
                        <div class="pe-2 d-flex align-items-center" @click.prevent.stop>
                            <span class="me-1">Attivo</span>
                            <toggler v-model="item.isActive"></toggler>
                        </div>
                    </div>
                </template>
                <div class="bg-body-secondary py-2 px-4">
                    <file-import-config v-model="config.fileImportConfigs[i]"></file-import-config>
                </div>
            </accordion>
        </div>
        <hr/>
        <div class="mb-2">
            <span>Configurazione triggers</span> <button class="btn btn-sm btn-success ms-2 squaredPlus" @click="addTrigger">+</button>
        </div>
        <div class="form-group">
            <accordion class="mb-3" v-for="(item, i) in config.triggerConfigs" :key="i">
                <template #title>
                    <div class="w-100 d-flex align-items-center justify-content-between py-2">
                        <div>
                            <button class="btn btn-sm btn-danger me-2" @click.stop="removeTrigger(i)">
                                Elimina
                            </button>
                            <span>Trigger {{i + 1}}</span>
                        </div>
                        <div class="pe-2 d-flex align-items-center" @click.prevent.stop>
                            <span class="me-1">Attivo</span>
                            <toggler v-model="item.isActive"></toggler>
                        </div>
                    </div>
                </template>
                <div class="bg-body-secondary py-2 px-4">
                    <trigger-config v-model="config.triggerConfigs[i]"></trigger-config>
                </div>
            </accordion>
        </div>
        <hr/>
        <div>
            <button class="btn btn-primary" @click="refreshJobInfos">Refresh</button>
        </div>
        <table class="table table-striped">
            <thead>
                <th>Job name</th>
                <th>Trigger name</th>
                <th>Start time</th>
                <th>Next fire time</th>
                <th>Prev fire time</th>
                <th>State</th>
                <th width="100"></th>
            </thead>
            <tbody>
                <tr v-for="(item, i) in jobInfos" :key="i">
                    <td>
                        {{item.jobName}}
                    </td>
                    <td>
                        {{item.triggerName}}
                    </td>
                    <td>
                        {{$filters.date(item.startTime, '', true)}}
                    </td>
                    <td>
                        {{$filters.date(item.nextFireTime, '', true)}}
                    </td>
                    <td>
                        {{$filters.date(item.previousFireTime, '', true)}}
                    </td>
                    <td>
                        {{item.state}}
                    </td>
                    <td class="text-end">
                        <button class="btn btn-sm btn-primary" @click="runJob(item)" v-if="item.state != 'Blocked'">
                            Run now
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
        
        <hr/>
        <div class="form-group">
            <accordion class="mb-3">
                <template #title>
                    <div class="w-100 d-flex align-items-center justify-content-between py-2">
                        <div>
                            Log job
                        </div>
                    </div>
                </template>
                <div class="bg-body-secondary py-2 px-4">
                    <table class="table table-striped">
                        <thead>
                            <th>Data ora</th>
                            <th>OnOffice</th>
                            <th>File</th>
                        </thead>
                        <tbody>
                            <tr v-for="(item, i) in jobLogs" :key="i">
                                <td>
                                    {{$filters.date(item.date, 'yyyy-MM-DD HH:mm:ss:SSS')}}
                                </td>
                                <td class="pre">
                                    <div v-for="(line, i) in item.onOfficeImportResult" :key="i">
                                        {{line}}
                                    </div>
                                </td>
                                <td class="pre">
                                    <div v-for="(line, i) in item.fileListImportResult" :key="i">
                                        {{line}}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </accordion>
        </div>
    
    </div>

</template>

<script lang="ts">
import { AppConfigClient, JobResultLogClient, QuartzClient } from '@/services/Services';
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/model';
import * as CONST from '@/const';
import fileImportConfig from '@/components/fileImportConfig.vue';
import { ModalServices } from '@/services/ModalServices';
import { Prop, Watch } from 'vue-property-decorator';

@Options({
    components: {
        fileImportConfig
    }
})
export default class importConfig extends Vue {
    
    @Prop() config: OM.ImportConfig;

    modules = CONST.DataFromApi.GetAll();
    jobInfos: OM.TriggerInfo[] = [];
    jobLogs: OM.JobResultLog[] = [];

    created(){
        this.init();
    }

    @Watch('config')
    configChange(){
        this.init();
    }

    init(){
        if(this.config.name){
            JobResultLogClient.getLogs(this.config.name)
            .then(x => {
                this.jobLogs = x;
            })
            this.refreshJobInfos();
        }
    }

    refreshJobInfos(){
        if(this.config.name){
            QuartzClient.getTriggerInfos(this.config.name).then(x => this.jobInfos = x);
        }
    }

    get allOnOfficeModules(){
        if(!this.config || this.config.onOfficeModules.length == 0) return false;

        return this.modules.every(x => this.config.onOfficeModules.some(c => x == c));
    }
    set allOnOfficeModules(value: boolean){
        this.config.onOfficeModules.splice(0);
        if(value)
            this.modules.forEach(x => this.config.onOfficeModules.push(x));
    }

    addFileConfig(){
        let toPush = new OM.CsvFileImportConfig();
        this.config.fileImportConfigs.push(toPush);
    }

    removeFileConfig(index){
        this.config.fileImportConfigs.splice(index, 1);
    }

    addTrigger(){
        let toPush = new OM.TriggerConfiguration();
        this.config.triggerConfigs.push(toPush);
    }

    removeTrigger(i){
        this.config.triggerConfigs.splice(i, 1);
    }

    runJob(item: OM.TriggerInfo){
        QuartzClient.startJobNow(item.jobName, item.jobGroup)
        .then(x => {
            this.refreshJobInfos();
        })
    }

}
</script>
